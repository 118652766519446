
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {

    },
    mixins: [Base],
    data() {
        return {
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            data: {
                labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
                datasets: [
                    {
                        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                        data: [40, 20, 80, 10],
                    },
                ],
            },
        };
    },
});
